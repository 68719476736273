import { useUniversalCtx } from "app/lib/universal-context"

function LifeFigure({ className }) {
  return (
    <svg
      className={className}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M147.211 73.6056C147.213 77.2963 146.914 80.981 146.318 84.6231C146.27 84.924 146.218 85.2249 146.164 85.5258C142.293 107.288 128.009 125.45 108.646 134.674C108.553 134.718 108.459 134.763 108.364 134.807C107.608 135.164 106.844 135.507 106.07 135.836C105.862 135.924 105.656 136.011 105.446 136.096C101.26 137.83 96.908 139.135 92.4581 139.991C92.2613 140.03 92.0644 140.065 91.8675 140.101C87.8388 140.839 83.7515 141.21 79.6558 141.211H79.4682C79.1042 141.211 78.7401 141.208 78.378 141.198C78.3092 141.198 78.2405 141.198 78.1718 141.195C74.0881 141.112 70.0202 140.661 66.0177 139.846C65.8226 139.805 65.6276 139.764 65.4326 139.722C61.8334 138.956 58.3036 137.893 54.8794 136.546C54.6696 136.466 54.4634 136.382 54.2554 136.297C53.4827 135.987 52.7182 135.661 51.9616 135.32C51.8669 135.277 51.7722 135.234 51.6793 135.192C31.7524 126.141 16.9924 107.704 13.0475 85.5258C12.9937 85.2249 12.9417 84.924 12.8934 84.6231C11.7047 77.3426 11.7022 69.9177 12.8859 62.6364C12.9528 62.2185 13.0252 61.7988 13.1032 61.3828C13.6347 58.4625 14.3607 55.581 15.2763 52.7575C15.3691 52.4696 15.4639 52.1817 15.5604 51.8938C24.6055 25.2082 49.8629 6 79.6056 6C112.77 6 140.356 29.8811 146.108 61.3828C146.186 61.7988 146.258 62.2185 146.325 62.6364C146.917 66.2629 147.213 69.9313 147.211 73.6056Z"
        fill="#B8E986"
      />
      <path
        d="M69.7424 32.5946H69.3818C69.3084 32.5946 69.1267 32.3063 69.1267 32.2236V31.8886C69.1267 31.6271 68.831 30.9099 68.5696 30.9099C68.3081 30.9099 68.0124 31.6271 68.0124 31.8886V32.2236C68.0124 32.3415 67.9214 32.5946 67.8101 32.5946H67.4495C67.1881 32.5946 66.4708 32.7975 66.4708 33.0589C66.4708 33.3203 67.1881 33.5232 67.4495 33.5232H67.8796C68.0157 33.5232 68.0124 33.6633 68.0124 33.7346V34.1815C68.0124 34.4429 68.3081 35.1602 68.5696 35.1602C68.831 35.1602 69.1267 34.4429 69.1267 34.1815V33.7346C69.1267 33.6754 69.2463 33.5232 69.3124 33.5232H69.7424C70.0038 33.5232 70.7211 33.3204 70.7211 33.0589C70.7211 32.7974 70.0038 32.5946 69.7424 32.5946Z"
        fill="#2771D5"
      />
      <path
        opacity="0.1"
        d="M19.2205 51.7689H18.7442C18.6472 51.7689 18.4072 51.3881 18.4072 51.2789V50.8363C18.4072 50.4909 18.0165 49.5435 17.6712 49.5435C17.3258 49.5435 16.9351 50.4909 16.9351 50.8363V51.2789C16.9351 51.4346 16.8149 51.7689 16.6679 51.7689H16.1915C15.8462 51.7689 14.8987 52.037 14.8987 52.3823C14.8987 52.7277 15.8462 52.9957 16.1915 52.9957H16.7597C16.9396 52.9957 16.9351 53.1808 16.9351 53.275V53.8653C16.9351 54.2107 17.3258 55.1582 17.6712 55.1582C18.0165 55.1582 18.4072 54.2107 18.4072 53.8653V53.275C18.4072 53.1968 18.5652 52.9957 18.6524 52.9957H19.2205C19.5659 52.9957 20.5134 52.7277 20.5134 52.3823C20.5134 52.037 19.5659 51.7689 19.2205 51.7689Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M70.1104 32.4533H69.6341C69.537 32.4533 69.2971 32.0725 69.2971 31.9632V31.5206C69.2971 31.1753 68.9064 30.2278 68.5611 30.2278C68.2157 30.2278 67.825 31.1753 67.825 31.5206V31.9632C67.825 32.1189 67.7048 32.4533 67.5578 32.4533H67.0814C66.7361 32.4533 65.7886 32.7213 65.7886 33.0667C65.7886 33.412 66.736 33.68 67.0814 33.68H67.6495C67.8294 33.68 67.825 33.8651 67.825 33.9593V34.5496C67.825 34.895 68.2157 35.8425 68.5611 35.8425C68.9064 35.8425 69.2971 34.895 69.2971 34.5496V33.9593C69.2971 33.8811 69.4551 33.68 69.5423 33.68H70.1104C70.4558 33.68 71.4033 33.412 71.4033 33.0667C71.4033 32.7213 70.4558 32.4533 70.1104 32.4533Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M121.557 41.1823H121.081C120.984 41.1823 120.744 40.8015 120.744 40.6922V40.2496C120.744 39.9043 120.353 38.9568 120.008 38.9568C119.663 38.9568 119.272 39.9043 119.272 40.2496V40.6922C119.272 40.8479 119.152 41.1823 119.005 41.1823H118.528C118.183 41.1823 117.236 41.4503 117.236 41.7957C117.236 42.1411 118.183 42.409 118.528 42.409H119.097C119.276 42.409 119.272 42.5941 119.272 42.6883V43.2786C119.272 43.624 119.663 44.5715 120.008 44.5715C120.353 44.5715 120.744 43.624 120.744 43.2786V42.6883C120.744 42.6101 120.902 42.409 120.989 42.409H121.557C121.903 42.409 122.85 42.141 122.85 41.7957C122.85 41.4503 121.903 41.1823 121.557 41.1823Z"
        fill="white"
      />
      <path
        d="M101.114 38.1667H100.884C100.838 38.1667 100.701 37.9849 100.701 37.9322V37.7187C100.701 37.5521 100.496 37.095 100.329 37.095C100.163 37.095 99.9578 37.5521 99.9578 37.7187V37.9322C99.9578 38.0073 99.9536 38.1667 99.8827 38.1667H99.6529C99.4863 38.1667 99.0292 38.2787 99.0292 38.4453C99.0292 38.612 99.4863 38.7239 99.6529 38.7239H99.927C100.014 38.7239 99.9578 38.8497 99.9578 38.8952V39.18C99.9578 39.3466 100.163 39.8037 100.329 39.8037C100.496 39.8037 100.701 39.3466 100.701 39.18V38.8952C100.701 38.8574 100.798 38.7239 100.84 38.7239H101.114C101.281 38.7239 101.738 38.612 101.738 38.4453C101.738 38.2787 101.281 38.1667 101.114 38.1667Z"
        fill="#2771D5"
      />
      <path
        opacity="0.1"
        d="M36.569 48.575H36.1944C36.1181 48.575 35.8951 48.2785 35.8951 48.1926V47.8446C35.8951 47.573 35.5612 46.8279 35.2896 46.8279C35.0179 46.8279 34.6841 47.573 34.6841 47.8446V48.1926C34.6841 48.3151 34.6772 48.575 34.5616 48.575H34.187C33.9154 48.575 33.1703 48.7575 33.1703 49.0291C33.1703 49.3007 33.9154 49.4832 34.187 49.4832H34.6337C34.7752 49.4832 34.6841 49.6883 34.6841 49.7624V50.2266C34.6841 50.4982 35.018 51.2433 35.2896 51.2433C35.5612 51.2433 35.8951 50.4982 35.8951 50.2266V49.7624C35.8951 49.7008 36.0536 49.4832 36.1222 49.4832H36.569C36.8406 49.4832 37.5857 49.3007 37.5857 49.0291C37.5857 48.7575 36.8406 48.575 36.569 48.575Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M101.574 37.9883H101.2C101.124 37.9883 100.901 37.6919 100.901 37.606V37.2579C100.901 36.9863 100.567 36.2412 100.295 36.2412C100.023 36.2412 99.6896 36.9863 99.6896 37.2579V37.606C99.6896 37.7284 99.6827 37.9883 99.5671 37.9883H99.1925C98.9209 37.9883 98.1758 38.1708 98.1758 38.4424C98.1758 38.714 98.9209 38.8965 99.1925 38.8965H99.6392C99.7807 38.8965 99.6896 39.1016 99.6896 39.1757V39.6399C99.6896 39.9115 100.023 40.6566 100.295 40.6566C100.567 40.6566 100.901 39.9115 100.901 39.6399V39.1757C100.901 39.1142 101.059 38.8965 101.128 38.8965H101.574C101.846 38.8965 102.591 38.714 102.591 38.4424C102.591 38.1708 101.846 37.9883 101.574 37.9883Z"
        fill="white"
      />
      <path
        d="M36.1089 48.7532H35.8791C35.8323 48.7532 35.6955 48.5713 35.6955 48.5186V48.3051C35.6955 48.1385 35.4907 47.6814 35.324 47.6814C35.1574 47.6814 34.9526 48.1385 34.9526 48.3051V48.5186C34.9526 48.5938 34.9484 48.7532 34.8774 48.7532H34.6476C34.481 48.7532 34.0239 48.8651 34.0239 49.0318C34.0239 49.1984 34.481 49.3104 34.6476 49.3104H34.9217C35.0085 49.3104 34.9526 49.4362 34.9526 49.4816V49.7664C34.9526 49.933 35.1574 50.3901 35.324 50.3901C35.4907 50.3901 35.6955 49.933 35.6955 49.7664V49.4816C35.6955 49.4439 35.7928 49.3104 35.8348 49.3104H36.1089C36.2755 49.3104 36.7326 49.1984 36.7326 49.0318C36.7326 48.8651 36.2755 48.7532 36.1089 48.7532Z"
        fill="#2771D5"
      />
      <path
        d="M88.9069 35.1946C89.6762 35.1946 90.2999 34.571 90.2999 33.8017C90.2999 33.0323 89.6762 32.4087 88.9069 32.4087C88.1376 32.4087 87.5139 33.0323 87.5139 33.8017C87.5139 34.571 88.1376 35.1946 88.9069 35.1946Z"
        fill="#44C7F3"
      />
      <path
        d="M86.1208 47.5148C86.3773 47.5148 86.5851 47.3069 86.5851 47.0505C86.5851 46.7941 86.3773 46.5862 86.1208 46.5862C85.8644 46.5862 85.6565 46.7941 85.6565 47.0505C85.6565 47.3069 85.8644 47.5148 86.1208 47.5148Z"
        fill="#2771D5"
      />
      <path
        d="M33.6445 37.7798C34.3583 37.7798 34.9369 37.2012 34.9369 36.4874C34.9369 35.7737 34.3583 35.1951 33.6445 35.1951C32.9308 35.1951 32.3522 35.7737 32.3522 36.4874C32.3522 37.2012 32.9308 37.7798 33.6445 37.7798Z"
        fill="#44C7F3"
      />
      <path
        d="M22.2259 42.0245C22.5828 42.0245 22.8721 41.7352 22.8721 41.3784C22.8721 41.0215 22.5828 40.7322 22.2259 40.7322C21.869 40.7322 21.5797 41.0215 21.5797 41.3784C21.5797 41.7352 21.869 42.0245 22.2259 42.0245Z"
        fill="#44C7F3"
      />
      <path
        d="M75.5306 55.2113C75.8875 55.2113 76.1768 54.922 76.1768 54.5651C76.1768 54.2082 75.8875 53.9189 75.5306 53.9189C75.1737 53.9189 74.8844 54.2082 74.8844 54.5651C74.8844 54.922 75.1737 55.2113 75.5306 55.2113Z"
        fill="#7DA7D8"
      />
      <path
        d="M27.4847 67.3107C28.1515 67.3107 28.692 66.7702 28.692 66.1035C28.692 65.4367 28.1515 64.8962 27.4847 64.8962C26.818 64.8962 26.2775 65.4367 26.2775 66.1035C26.2775 66.7702 26.818 67.3107 27.4847 67.3107Z"
        fill="#7DA7D8"
      />
      <path
        opacity="0.1"
        d="M51.4282 34.7822C52.051 34.7822 52.5559 34.2773 52.5559 33.6545C52.5559 33.0317 52.051 32.5269 51.4282 32.5269C50.8054 32.5269 50.3005 33.0317 50.3005 33.6545C50.3005 34.2773 50.8054 34.7822 51.4282 34.7822Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M51.614 48.8977C52.2368 48.8977 52.7416 48.3928 52.7416 47.77C52.7416 47.1472 52.2368 46.6423 51.614 46.6423C50.9912 46.6423 50.4863 47.1472 50.4863 47.77C50.4863 48.3928 50.9912 48.8977 51.614 48.8977Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M110.862 31.8107C111.485 31.8107 111.989 31.3059 111.989 30.6831C111.989 30.0603 111.485 29.5554 110.862 29.5554C110.239 29.5554 109.734 30.0603 109.734 30.6831C109.734 31.3059 110.239 31.8107 110.862 31.8107Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M136.678 51.8696C137.301 51.8696 137.806 51.3647 137.806 50.7419C137.806 50.1191 137.301 49.6143 136.678 49.6143C136.056 49.6143 135.551 50.1191 135.551 50.7419C135.551 51.3647 136.056 51.8696 136.678 51.8696Z"
        fill="white"
      />
      <path
        d="M51.4281 34.3436C51.8085 34.3436 52.1169 34.0352 52.1169 33.6548C52.1169 33.2744 51.8085 32.9661 51.4281 32.9661C51.0477 32.9661 50.7394 33.2744 50.7394 33.6548C50.7394 34.0352 51.0477 34.3436 51.4281 34.3436Z"
        fill="#204896"
      />
      <path
        d="M68.6624 45.2239C69.4317 45.2239 70.0554 44.6003 70.0554 43.831C70.0554 43.0616 69.4317 42.438 68.6624 42.438C67.8931 42.438 67.2694 43.0616 67.2694 43.831C67.2694 44.6003 67.8931 45.2239 68.6624 45.2239Z"
        fill="#44C7F3"
      />
      <path
        d="M46.0617 53.2103H45.7011C45.6277 53.2103 45.4461 52.922 45.4461 52.8393V52.5043C45.4461 52.2429 45.1503 51.5256 44.8889 51.5256C44.6274 51.5256 44.3317 52.2429 44.3317 52.5043V52.8393C44.3317 52.9572 44.2407 53.2103 44.1294 53.2103H43.7688C43.5074 53.2103 42.7902 53.4132 42.7902 53.6746C42.7902 53.9361 43.5074 54.1389 43.7688 54.1389H44.1989C44.3351 54.1389 44.3317 54.279 44.3317 54.3504V54.7972C44.3317 55.0587 44.6274 55.7759 44.8889 55.7759C45.1503 55.7759 45.4461 55.0587 45.4461 54.7972V54.3504C45.4461 54.2911 45.5656 54.1389 45.6317 54.1389H46.0617C46.3232 54.1389 47.0404 53.9361 47.0404 53.6746C47.0404 53.4132 46.3232 53.2103 46.0617 53.2103Z"
        fill="#2771D5"
      />
      <path
        d="M40.2686 83.8121H39.9081C39.8346 83.8121 39.653 83.5239 39.653 83.4411V83.1061C39.653 82.8447 39.3572 82.1274 39.0958 82.1274C38.8344 82.1274 38.5386 82.8447 38.5386 83.1061V83.4411C38.5386 83.559 38.4476 83.8121 38.3363 83.8121H37.9757C37.7143 83.8121 36.9971 84.015 36.9971 84.2764C36.9971 84.5379 37.7143 84.7408 37.9757 84.7408H38.4058C38.542 84.7408 38.5386 84.8808 38.5386 84.9522V85.399C38.5386 85.6605 38.8344 86.3777 39.0958 86.3777C39.3572 86.3777 39.653 85.6605 39.653 85.399V84.9522C39.653 84.8929 39.7726 84.7408 39.8386 84.7408H40.2686C40.5301 84.7408 41.2473 84.5379 41.2473 84.2764C41.2473 84.015 40.5301 83.8121 40.2686 83.8121Z"
        fill="#2771D5"
      />
      <path
        d="M61.5119 66.8306C61.8709 66.8306 62.1619 66.5396 62.1619 66.1806C62.1619 65.8216 61.8709 65.5305 61.5119 65.5305C61.1529 65.5305 60.8618 65.8216 60.8618 66.1806C60.8618 66.5396 61.1529 66.8306 61.5119 66.8306Z"
        fill="#204896"
      />
      <path
        d="M131.989 54.8667C132.702 54.8667 133.281 54.2881 133.281 53.5743C133.281 52.8606 132.702 52.282 131.989 52.282C131.275 52.282 130.696 52.8606 130.696 53.5743C130.696 54.2881 131.275 54.8667 131.989 54.8667Z"
        fill="#44C7F3"
      />
      <path
        d="M113.281 68.805C113.994 68.805 114.573 68.2263 114.573 67.5126C114.573 66.7988 113.994 66.2202 113.281 66.2202C112.567 66.2202 111.988 66.7988 111.988 67.5126C111.988 68.2263 112.567 68.805 113.281 68.805Z"
        fill="#44C7F3"
      />
      <path
        d="M82.685 63.4875C82.9415 63.4875 83.1494 63.2796 83.1494 63.0232C83.1494 62.7667 82.9415 62.5588 82.685 62.5588C82.4286 62.5588 82.2207 62.7667 82.2207 63.0232C82.2207 63.2796 82.4286 63.4875 82.685 63.4875Z"
        fill="#2771D5"
      />
      <path
        d="M51.614 48.459C51.9944 48.459 52.3028 48.1507 52.3028 47.7703C52.3028 47.3899 51.9944 47.0815 51.614 47.0815C51.2337 47.0815 50.9253 47.3899 50.9253 47.7703C50.9253 48.1507 51.2337 48.459 51.614 48.459Z"
        fill="#204896"
      />
      <path
        d="M121.189 41.3238H120.829C120.755 41.3238 120.574 41.0356 120.574 40.9529V40.6178C120.574 40.3564 120.278 39.6392 120.016 39.6392C119.755 39.6392 119.459 40.3564 119.459 40.6178V40.9529C119.459 41.0707 119.368 41.3238 119.257 41.3238H118.896C118.635 41.3238 117.918 41.5267 117.918 41.7882C117.918 42.0496 118.635 42.2525 118.896 42.2525H119.326C119.463 42.2525 119.459 42.3926 119.459 42.4639V42.9107C119.459 43.1722 119.755 43.8894 120.016 43.8894C120.278 43.8894 120.574 43.1722 120.574 42.9107V42.4639C120.574 42.4047 120.693 42.2525 120.759 42.2525H121.189C121.451 42.2525 122.168 42.0496 122.168 41.7882C122.168 41.5267 121.451 41.3238 121.189 41.3238Z"
        fill="#2771D5"
      />
      <path
        d="M136.639 51.2298C136.998 51.2298 137.289 50.9388 137.289 50.5797C137.289 50.2207 136.998 49.9297 136.639 49.9297C136.28 49.9297 135.989 50.2207 135.989 50.5797C135.989 50.9388 136.28 51.2298 136.639 51.2298Z"
        fill="#204896"
      />
      <path
        d="M110.823 31.3565C111.182 31.3565 111.473 31.0655 111.473 30.7065C111.473 30.3474 111.182 30.0564 110.823 30.0564C110.464 30.0564 110.173 30.3474 110.173 30.7065C110.173 31.0655 110.464 31.3565 110.823 31.3565Z"
        fill="#204896"
      />
      <path
        opacity="0.1"
        d="M129.853 50.8439C130.806 50.8439 131.58 50.0706 131.58 49.1168C131.58 48.1629 130.806 47.3896 129.853 47.3896C128.899 47.3896 128.125 48.1629 128.125 49.1168C128.125 50.0706 128.899 50.8439 129.853 50.8439Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M104.779 54.0013C105.733 54.0013 106.506 53.2281 106.506 52.2742C106.506 51.3204 105.733 50.5471 104.779 50.5471C103.825 50.5471 103.052 51.3204 103.052 52.2742C103.052 53.2281 103.825 54.0013 104.779 54.0013Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M88.9921 35.6141C89.946 35.6141 90.7192 34.8409 90.7192 33.887C90.7192 32.9332 89.946 32.1599 88.9921 32.1599C88.0383 32.1599 87.265 32.9332 87.265 33.887C87.265 34.8409 88.0383 35.6141 88.9921 35.6141Z"
        fill="white"
      />
      <path
        d="M68.7475 45.6432C69.7013 45.6432 70.4746 44.8699 70.4746 43.9161C70.4746 42.9622 69.7013 42.189 68.7475 42.189C67.7936 42.189 67.0204 42.9622 67.0204 43.9161C67.0204 44.8699 67.7936 45.6432 68.7475 45.6432Z"
        fill="#F6931D"
      />
      <path
        opacity="0.1"
        d="M33.6447 38.2142C34.5986 38.2142 35.3718 37.441 35.3718 36.4871C35.3718 35.5333 34.5986 34.76 33.6447 34.76C32.6909 34.76 31.9176 35.5333 31.9176 36.4871C31.9176 37.441 32.6909 38.2142 33.6447 38.2142Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M27.5154 67.7452C28.4693 67.7452 29.2425 66.972 29.2425 66.0181C29.2425 65.0643 28.4693 64.291 27.5154 64.291C26.5616 64.291 25.7883 65.0643 25.7883 66.0181C25.7883 66.972 26.5616 67.7452 27.5154 67.7452Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M124.563 31.7253C125.079 31.7253 125.497 31.3074 125.497 30.7918C125.497 30.2763 125.079 29.8584 124.563 29.8584C124.048 29.8584 123.63 30.2763 123.63 30.7918C123.63 31.3074 124.048 31.7253 124.563 31.7253Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M75.5304 55.4987C76.0459 55.4987 76.4638 55.0808 76.4638 54.5653C76.4638 54.0498 76.0459 53.6318 75.5304 53.6318C75.0148 53.6318 74.5969 54.0498 74.5969 54.5653C74.5969 55.0808 75.0148 55.4987 75.5304 55.4987Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M22.226 42.3117C22.7416 42.3117 23.1595 41.8938 23.1595 41.3783C23.1595 40.8627 22.7416 40.4448 22.226 40.4448C21.7105 40.4448 21.2926 40.8627 21.2926 41.3783C21.2926 41.8938 21.7105 42.3117 22.226 42.3117Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M34.2986 65.8991C34.8141 65.8991 35.232 65.4812 35.232 64.9657C35.232 64.4501 34.8141 64.0322 34.2986 64.0322C33.783 64.0322 33.3651 64.4501 33.3651 64.9657C33.3651 65.4812 33.783 65.8991 34.2986 65.8991Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M55.4718 62.7419C55.9873 62.7419 56.4052 62.324 56.4052 61.8084C56.4052 61.2929 55.9873 60.875 55.4718 60.875C54.9562 60.875 54.5383 61.2929 54.5383 61.8084C54.5383 62.324 54.9562 62.7419 55.4718 62.7419Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M86.1172 48.0695C86.6327 48.0695 87.0506 47.6516 87.0506 47.1361C87.0506 46.6206 86.6327 46.2026 86.1172 46.2026C85.6016 46.2026 85.1837 46.6206 85.1837 47.1361C85.1837 47.6516 85.6016 48.0695 86.1172 48.0695Z"
        fill="white"
      />
      <path
        d="M124.563 31.4379C124.92 31.4379 125.209 31.1486 125.209 30.7917C125.209 30.4348 124.92 30.1455 124.563 30.1455C124.206 30.1455 123.917 30.4348 123.917 30.7917C123.917 31.1486 124.206 31.4379 124.563 31.4379Z"
        fill="#7DA7D8"
      />
      <path
        d="M130.814 85.6923C131.171 85.6923 131.46 85.403 131.46 85.0461C131.46 84.6892 131.171 84.3999 130.814 84.3999C130.457 84.3999 130.168 84.6892 130.168 85.0461C130.168 85.403 130.457 85.6923 130.814 85.6923Z"
        fill="#7DA7D8"
      />
      <path
        d="M129.822 50.4091C130.489 50.4091 131.029 49.8686 131.029 49.2019C131.029 48.5351 130.489 47.9946 129.822 47.9946C129.155 47.9946 128.615 48.5351 128.615 49.2019C128.615 49.8686 129.155 50.4091 129.822 50.4091Z"
        fill="#A46AAC"
      />
      <path
        opacity="0.1"
        d="M79.9258 61.6753L39.9083 99.3499V154.827H119.942V99.3499L79.9258 61.6753Z"
        fill="white"
      />
      <path
        d="M119.537 153.61H40.3142V99.26L79.9261 62.3516L119.537 99.26V153.61Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M107.504 69.9221H52.3468V134.817H107.504V69.9221Z"
        fill="white"
      />
      <path
        d="M106.807 70.4631H53.0433V134.817H106.807V70.4631Z"
        fill="#B8E986"
      />
      <path
        d="M40.3142 99.2598L79.9261 126.435L40.3142 153.61V99.2598Z"
        fill="#F0F0F0"
      />
      <path
        d="M119.537 99.2598L79.926 126.435L119.537 153.61V99.2598Z"
        fill="#F0F0F0"
      />
      <path
        d="M40.3142 153.609L78.3704 123.998C78.8133 123.65 79.3604 123.46 79.924 123.46C80.4876 123.46 81.0348 123.65 81.4776 123.998L119.537 153.609H40.3142Z"
        fill="white"
      />
      <path
        d="M40.3142 153.608L78.3704 125.291C78.8195 124.957 79.3643 124.777 79.924 124.777C80.4837 124.777 81.0285 124.957 81.4776 125.291L119.537 153.608H40.3142Z"
        fill="white"
      />
      <path
        d="M72.4223 82.9006H61.201V84.7937H72.4223V82.9006Z"
        fill="#B8E986"
      />
      <path
        d="M98.6495 87.9038H61.201V89.7969H98.6495V87.9038Z"
        fill="#F0F0F0"
      />
      <path
        d="M98.6495 91.1477H61.201V93.0408H98.6495V91.1477Z"
        fill="#F0F0F0"
      />
      <path
        d="M98.6495 94.3931H61.201V96.2862H98.6495V94.3931Z"
        fill="#F0F0F0"
      />
      <path d="M98.6495 97.637H61.201V99.5301H98.6495V97.637Z" fill="#F0F0F0" />
      <path
        d="M78.2351 100.883H61.201V102.776H78.2351V100.883Z"
        fill="#F0F0F0"
      />
      <path
        d="M98.6494 100.883H81.6154V102.776H98.6494V100.883Z"
        fill="#24A159"
      />
    </svg>
  )
}

function ReachFigure({ className }) {
  return (
    <svg
      className={className}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M147.606 73.2394C147.608 76.9301 147.309 80.6148 146.712 84.2569C146.664 84.5578 146.612 84.8587 146.558 85.1596C142.688 106.922 128.403 125.084 109.041 134.307C108.948 134.352 108.853 134.397 108.758 134.441C108.003 134.798 107.238 135.141 106.465 135.47C106.257 135.557 106.05 135.645 105.841 135.73C101.654 137.464 97.3024 138.769 92.8525 139.625C92.6557 139.664 92.4588 139.699 92.2619 139.734C88.2332 140.472 84.1459 140.844 80.0502 140.845H79.8626C79.4986 140.845 79.1345 140.841 78.7724 140.832C78.7036 140.832 78.6349 140.832 78.5662 140.828C74.4825 140.746 70.4146 140.295 66.412 139.48C66.217 139.439 66.022 139.398 65.827 139.356C62.2278 138.589 58.698 137.527 55.2738 136.18C55.064 136.1 54.8578 136.016 54.6498 135.931C53.8771 135.62 53.1126 135.295 52.356 134.954C52.2613 134.911 52.1666 134.868 52.0737 134.826C32.1468 125.775 17.3868 107.338 13.4419 85.1596C13.388 84.8587 13.336 84.5578 13.2878 84.2569C12.0991 76.9764 12.0966 69.5515 13.2803 62.2702C13.3472 61.8523 13.4196 61.4326 13.4976 61.0166C14.0291 58.0963 14.7551 55.2148 15.6707 52.3913C15.7635 52.1034 15.8583 51.8155 15.9548 51.5276C24.9999 24.842 50.2573 5.6338 80 5.6338C113.164 5.6338 140.75 29.5149 146.502 61.0166C146.58 61.4326 146.653 61.8523 146.72 62.2702C147.311 65.8967 147.607 69.5651 147.606 73.2394Z"
        fill="#BBEBFF"
      />
      <path
        d="M70.1367 32.2284H69.7761C69.7026 32.2284 69.521 31.9402 69.521 31.8574V31.5224C69.521 31.261 69.2253 30.5437 68.9638 30.5437C68.7024 30.5437 68.4066 31.261 68.4066 31.5224V31.8574C68.4066 31.9753 68.3156 32.2284 68.2044 32.2284H67.8438C67.5823 32.2284 66.8651 32.4313 66.8651 32.6927C66.8651 32.9542 67.5823 33.1571 67.8438 33.1571H68.2738C68.41 33.1571 68.4066 33.2971 68.4066 33.3685V33.8153C68.4066 34.0768 68.7024 34.794 68.9638 34.794C69.2253 34.794 69.521 34.0768 69.521 33.8153V33.3685C69.521 33.3092 69.6406 33.1571 69.7066 33.1571H70.1367C70.3981 33.1571 71.1153 32.9542 71.1153 32.6927C71.1153 32.4313 70.3981 32.2284 70.1367 32.2284Z"
        fill="#2196F3"
      />
      <path
        d="M19.6147 51.4027H19.1383C19.0413 51.4027 18.8014 51.0219 18.8014 50.9127V50.4701C18.8014 50.1247 18.4107 49.1772 18.0653 49.1772C17.7199 49.1772 17.3292 50.1247 17.3292 50.4701V50.9127C17.3292 51.0684 17.209 51.4027 17.062 51.4027H16.5857C16.2403 51.4027 15.2928 51.6707 15.2928 52.0161C15.2928 52.3615 16.2403 52.6295 16.5857 52.6295H17.1538C17.3337 52.6295 17.3292 52.8145 17.3292 52.9088V53.4991C17.3292 53.8445 17.7199 54.7919 18.0653 54.7919C18.4106 54.7919 18.8014 53.8445 18.8014 53.4991V52.9088C18.8014 52.8305 18.9593 52.6295 19.0466 52.6295H19.6147C19.96 52.6295 20.9075 52.3615 20.9075 52.0161C20.9075 51.6707 19.96 51.4027 19.6147 51.4027Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M70.5047 32.0871H70.0283C69.9313 32.0871 69.6914 31.7063 69.6914 31.597V31.1545C69.6914 30.8091 69.3007 29.8616 68.9553 29.8616C68.6099 29.8616 68.2192 30.8091 68.2192 31.1545V31.597C68.2192 31.7528 68.099 32.0871 67.952 32.0871H67.4757C67.1303 32.0871 66.1828 32.3551 66.1828 32.7005C66.1828 33.0459 67.1303 33.3139 67.4757 33.3139H68.0438C68.2237 33.3139 68.2192 33.4989 68.2192 33.5931V34.1835C68.2192 34.5288 68.6099 35.4763 68.9553 35.4763C69.3006 35.4763 69.6914 34.5288 69.6914 34.1835V33.5931C69.6914 33.5149 69.8493 33.3139 69.9365 33.3139H70.5047C70.85 33.3139 71.7975 33.0459 71.7975 32.7005C71.7975 32.3551 70.85 32.0871 70.5047 32.0871Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M121.952 40.816H121.475C121.378 40.816 121.138 40.4352 121.138 40.326V39.8834C121.138 39.538 120.748 38.5906 120.402 38.5906C120.057 38.5906 119.666 39.538 119.666 39.8834V40.326C119.666 40.4817 119.546 40.816 119.399 40.816H118.923C118.577 40.816 117.63 41.084 117.63 41.4294C117.63 41.7748 118.577 42.0428 118.923 42.0428H119.491C119.671 42.0428 119.666 42.2279 119.666 42.3221V42.9124C119.666 43.2578 120.057 44.2053 120.402 44.2053C120.748 44.2053 121.138 43.2578 121.138 42.9124V42.3221C121.138 42.2439 121.296 42.0428 121.384 42.0428H121.952C122.297 42.0428 123.245 41.7748 123.245 41.4294C123.245 41.0841 122.297 40.816 121.952 40.816Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M101.508 37.8004H101.279C101.232 37.8004 101.095 37.6186 101.095 37.5659V37.3524C101.095 37.1858 100.89 36.7287 100.724 36.7287C100.557 36.7287 100.352 37.1858 100.352 37.3524V37.5659C100.352 37.641 100.348 37.8004 100.277 37.8004H100.047C99.8805 37.8004 99.4234 37.9124 99.4234 38.079C99.4234 38.2456 99.8805 38.3576 100.047 38.3576H100.321C100.408 38.3576 100.352 38.4834 100.352 38.5289V38.8136C100.352 38.9803 100.557 39.4374 100.724 39.4374C100.89 39.4374 101.095 38.9803 101.095 38.8136V38.5289C101.095 38.4911 101.192 38.3576 101.234 38.3576H101.508C101.675 38.3576 102.132 38.2457 102.132 38.079C102.132 37.9124 101.675 37.8004 101.508 37.8004Z"
        fill="#1976D2"
      />
      <path
        d="M36.9634 48.2088H36.5888C36.5125 48.2088 36.2895 47.9124 36.2895 47.8265V47.4785C36.2895 47.2069 35.9556 46.4618 35.684 46.4618C35.4124 46.4618 35.0785 47.2069 35.0785 47.4785V47.8265C35.0785 47.949 35.0716 48.2088 34.956 48.2088H34.5814C34.3098 48.2088 33.5647 48.3914 33.5647 48.663C33.5647 48.9346 34.3098 49.1171 34.5814 49.1171H35.0282C35.1696 49.1171 35.0785 49.3221 35.0785 49.3963V49.8605C35.0785 50.1321 35.4124 50.8772 35.684 50.8772C35.9556 50.8772 36.2895 50.1321 36.2895 49.8605V49.3962C36.2895 49.3347 36.448 49.1171 36.5166 49.1171H36.9634C37.235 49.1171 37.9801 48.9346 37.9801 48.663C37.9801 48.3914 37.235 48.2088 36.9634 48.2088Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M101.969 37.6222H101.594C101.518 37.6222 101.295 37.3258 101.295 37.2398V36.8918C101.295 36.6202 100.961 35.8751 100.689 35.8751C100.418 35.8751 100.084 36.6202 100.084 36.8918V37.2398C100.084 37.3623 100.077 37.6222 99.9613 37.6222H99.5867C99.3151 37.6222 98.57 37.8047 98.57 38.0763C98.57 38.3479 99.3151 38.5304 99.5867 38.5304H100.033C100.175 38.5304 100.084 38.7355 100.084 38.8096V39.2738C100.084 39.5454 100.418 40.2905 100.689 40.2905C100.961 40.2905 101.295 39.5454 101.295 39.2738V38.8096C101.295 38.7481 101.453 38.5304 101.522 38.5304H101.969C102.24 38.5304 102.985 38.3479 102.985 38.0763C102.985 37.8047 102.24 37.6222 101.969 37.6222Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M36.5031 48.3868H36.2733C36.2265 48.3868 36.0897 48.205 36.0897 48.1523V47.9388C36.0897 47.7721 35.8848 47.315 35.7182 47.315C35.5516 47.315 35.3467 47.7721 35.3467 47.9388V48.1523C35.3467 48.2274 35.3425 48.3868 35.2716 48.3868H35.0418C34.8752 48.3868 34.4181 48.4988 34.4181 48.6654C34.4181 48.832 34.8752 48.944 35.0418 48.944H35.3159C35.4027 48.944 35.3467 49.0698 35.3467 49.1152V49.4C35.3467 49.5667 35.5516 50.0237 35.7182 50.0237C35.8848 50.0237 36.0897 49.5667 36.0897 49.4V49.1152C36.0897 49.0775 36.1869 48.944 36.229 48.944H36.5031C36.6697 48.944 37.1268 48.832 37.1268 48.6654C37.1268 48.4988 36.6697 48.3868 36.5031 48.3868Z"
        fill="#1976D2"
      />
      <path
        d="M89.3012 34.8285C90.0705 34.8285 90.6941 34.2048 90.6941 33.4355C90.6941 32.6662 90.0705 32.0425 89.3012 32.0425C88.5318 32.0425 87.9082 32.6662 87.9082 33.4355C87.9082 34.2048 88.5318 34.8285 89.3012 34.8285Z"
        fill="#64B5F6"
      />
      <path
        d="M86.5153 47.1485C86.7718 47.1485 86.9797 46.9406 86.9797 46.6842C86.9797 46.4278 86.7718 46.2199 86.5153 46.2199C86.2589 46.2199 86.051 46.4278 86.051 46.6842C86.051 46.9406 86.2589 47.1485 86.5153 47.1485Z"
        fill="#0071BC"
      />
      <path
        d="M34.0388 37.4135C34.7526 37.4135 35.3312 36.8348 35.3312 36.1211C35.3312 35.4073 34.7526 34.8287 34.0388 34.8287C33.3251 34.8287 32.7465 35.4073 32.7465 36.1211C32.7465 36.8348 33.3251 37.4135 34.0388 37.4135Z"
        fill="#64B5F6"
      />
      <path
        d="M22.6204 41.6583C22.9773 41.6583 23.2666 41.369 23.2666 41.0121C23.2666 40.6552 22.9773 40.3659 22.6204 40.3659C22.2635 40.3659 21.9742 40.6552 21.9742 41.0121C21.9742 41.369 22.2635 41.6583 22.6204 41.6583Z"
        fill="#64B5F6"
      />
      <path
        d="M75.9248 54.845C76.2817 54.845 76.571 54.5557 76.571 54.1988C76.571 53.842 76.2817 53.5527 75.9248 53.5527C75.5679 53.5527 75.2786 53.842 75.2786 54.1988C75.2786 54.5557 75.5679 54.845 75.9248 54.845Z"
        fill="#82A9FF"
      />
      <path
        d="M27.879 66.9444C28.5457 66.9444 29.0862 66.4039 29.0862 65.7372C29.0862 65.0704 28.5457 64.5299 27.879 64.5299C27.2122 64.5299 26.6717 65.0704 26.6717 65.7372C26.6717 66.4039 27.2122 66.9444 27.879 66.9444Z"
        fill="#82A9FF"
      />
      <path
        d="M51.8226 34.416C52.4454 34.416 52.9502 33.9112 52.9502 33.2884C52.9502 32.6656 52.4454 32.1607 51.8226 32.1607C51.1998 32.1607 50.6949 32.6656 50.6949 33.2884C50.6949 33.9112 51.1998 34.416 51.8226 34.416Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M52.0082 48.5315C52.631 48.5315 53.1359 48.0266 53.1359 47.4038C53.1359 46.7811 52.631 46.2762 52.0082 46.2762C51.3855 46.2762 50.8806 46.7811 50.8806 47.4038C50.8806 48.0266 51.3855 48.5315 52.0082 48.5315Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M111.256 31.4445C111.879 31.4445 112.384 30.9396 112.384 30.3169C112.384 29.6941 111.879 29.1892 111.256 29.1892C110.633 29.1892 110.128 29.6941 110.128 30.3169C110.128 30.9396 110.633 31.4445 111.256 31.4445Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M137.072 51.5033C137.695 51.5033 138.2 50.9984 138.2 50.3756C138.2 49.7528 137.695 49.248 137.072 49.248C136.45 49.248 135.945 49.7528 135.945 50.3756C135.945 50.9984 136.45 51.5033 137.072 51.5033Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M51.8225 33.9773C52.2028 33.9773 52.5112 33.669 52.5112 33.2886C52.5112 32.9082 52.2028 32.5998 51.8225 32.5998C51.4421 32.5998 51.1337 32.9082 51.1337 33.2886C51.1337 33.669 51.4421 33.9773 51.8225 33.9773Z"
        fill="#0D47A1"
      />
      <path
        d="M69.0566 44.8579C69.826 44.8579 70.4496 44.2342 70.4496 43.4649C70.4496 42.6956 69.826 42.0719 69.0566 42.0719C68.2873 42.0719 67.6637 42.6956 67.6637 43.4649C67.6637 44.2342 68.2873 44.8579 69.0566 44.8579Z"
        fill="#64B5F6"
      />
      <path
        d="M46.4561 52.8442H46.0955C46.022 52.8442 45.8404 52.5559 45.8404 52.4732V52.1382C45.8404 51.8768 45.5447 51.1595 45.2832 51.1595C45.0218 51.1595 44.726 51.8768 44.726 52.1382V52.4732C44.726 52.5911 44.635 52.8442 44.5238 52.8442H44.1632C43.9017 52.8442 43.1845 53.0471 43.1845 53.3085C43.1845 53.57 43.9017 53.7728 44.1632 53.7728H44.5932C44.7294 53.7728 44.726 53.9129 44.726 53.9842V54.4311C44.726 54.6926 45.0218 55.4098 45.2832 55.4098C45.5447 55.4098 45.8404 54.6926 45.8404 54.4311V53.9842C45.8404 53.925 45.96 53.7728 46.026 53.7728H46.4561C46.7175 53.7728 47.4347 53.57 47.4347 53.3085C47.4347 53.0471 46.7175 52.8442 46.4561 52.8442Z"
        fill="#2196F3"
      />
      <path
        d="M40.663 83.4458H40.3024C40.2289 83.4458 40.0473 83.1576 40.0473 83.0748V82.7398C40.0473 82.4784 39.7516 81.7611 39.4901 81.7611C39.2287 81.7611 38.9329 82.4784 38.9329 82.7398V83.0748C38.9329 83.1927 38.8419 83.4458 38.7307 83.4458H38.3701C38.1086 83.4458 37.3914 83.6487 37.3914 83.9101C37.3914 84.1716 38.1086 84.3745 38.3701 84.3745H38.8001C38.9363 84.3745 38.9329 84.5145 38.9329 84.5859V85.0327C38.9329 85.2942 39.2287 86.0114 39.4901 86.0114C39.7516 86.0114 40.0473 85.2942 40.0473 85.0327V84.5859C40.0473 84.5267 40.1669 84.3745 40.2329 84.3745H40.663C40.9244 84.3745 41.6416 84.1716 41.6416 83.9101C41.6416 83.6487 40.9244 83.4458 40.663 83.4458Z"
        fill="#2196F3"
      />
      <path
        d="M61.906 66.4644C62.2651 66.4644 62.5561 66.1734 62.5561 65.8144C62.5561 65.4554 62.2651 65.1643 61.906 65.1643C61.547 65.1643 61.256 65.4554 61.256 65.8144C61.256 66.1734 61.547 66.4644 61.906 66.4644Z"
        fill="#0D47A1"
      />
      <path
        d="M132.383 54.5005C133.097 54.5005 133.675 53.9218 133.675 53.2081C133.675 52.4943 133.097 51.9157 132.383 51.9157C131.669 51.9157 131.09 52.4943 131.09 53.2081C131.09 53.9218 131.669 54.5005 132.383 54.5005Z"
        fill="#64B5F6"
      />
      <path
        d="M113.675 68.4387C114.389 68.4387 114.967 67.8601 114.967 67.1463C114.967 66.4326 114.389 65.8539 113.675 65.8539C112.961 65.8539 112.383 66.4326 112.383 67.1463C112.383 67.8601 112.961 68.4387 113.675 68.4387Z"
        fill="#64B5F6"
      />
      <path
        d="M83.0793 63.1212C83.3357 63.1212 83.5436 62.9133 83.5436 62.6569C83.5436 62.4004 83.3357 62.1926 83.0793 62.1926C82.8228 62.1926 82.615 62.4004 82.615 62.6569C82.615 62.9133 82.8228 63.1212 83.0793 63.1212Z"
        fill="#0071BC"
      />
      <path
        d="M52.0081 48.0928C52.3885 48.0928 52.6969 47.7844 52.6969 47.4041C52.6969 47.0237 52.3885 46.7153 52.0081 46.7153C51.6277 46.7153 51.3194 47.0237 51.3194 47.4041C51.3194 47.7844 51.6277 48.0928 52.0081 48.0928Z"
        fill="#0D47A1"
      />
      <path
        d="M121.584 40.9576H121.223C121.15 40.9576 120.968 40.6694 120.968 40.5866V40.2516C120.968 39.9902 120.672 39.2729 120.411 39.2729C120.15 39.2729 119.854 39.9902 119.854 40.2516V40.5866C119.854 40.7045 119.763 40.9576 119.651 40.9576H119.291C119.029 40.9576 118.312 41.1605 118.312 41.4219C118.312 41.6834 119.029 41.8863 119.291 41.8863H119.721C119.857 41.8863 119.854 42.0263 119.854 42.0977V42.5445C119.854 42.806 120.15 43.5232 120.411 43.5232C120.672 43.5232 120.968 42.806 120.968 42.5445V42.0977C120.968 42.0385 121.088 41.8863 121.154 41.8863H121.584C121.845 41.8863 122.562 41.6834 122.562 41.4219C122.562 41.1605 121.845 40.9576 121.584 40.9576Z"
        fill="#2196F3"
      />
      <path
        d="M137.034 50.8635C137.393 50.8635 137.684 50.5724 137.684 50.2134C137.684 49.8544 137.393 49.5634 137.034 49.5634C136.675 49.5634 136.384 49.8544 136.384 50.2134C136.384 50.5724 136.675 50.8635 137.034 50.8635Z"
        fill="#0D47A1"
      />
      <path
        d="M111.217 30.9903C111.576 30.9903 111.867 30.6993 111.867 30.3402C111.867 29.9812 111.576 29.6902 111.217 29.6902C110.858 29.6902 110.567 29.9812 110.567 30.3402C110.567 30.6993 110.858 30.9903 111.217 30.9903Z"
        fill="#0D47A1"
      />
      <path
        d="M130.247 50.4777C131.201 50.4777 131.974 49.7045 131.974 48.7506C131.974 47.7967 131.201 47.0235 130.247 47.0235C129.293 47.0235 128.52 47.7967 128.52 48.7506C128.52 49.7045 129.293 50.4777 130.247 50.4777Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M105.173 53.6351C106.127 53.6351 106.901 52.8618 106.901 51.908C106.901 50.9541 106.127 50.1809 105.173 50.1809C104.22 50.1809 103.446 50.9541 103.446 51.908C103.446 52.8618 104.22 53.6351 105.173 53.6351Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M89.3863 35.2479C90.3402 35.2479 91.1135 34.4747 91.1135 33.5208C91.1135 32.567 90.3402 31.7937 89.3863 31.7937C88.4325 31.7937 87.6592 32.567 87.6592 33.5208C87.6592 34.4747 88.4325 35.2479 89.3863 35.2479Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M69.1418 45.277C70.0957 45.277 70.8689 44.5038 70.8689 43.5499C70.8689 42.5961 70.0957 41.8228 69.1418 41.8228C68.188 41.8228 67.4147 42.5961 67.4147 43.5499C67.4147 44.5038 68.188 45.277 69.1418 45.277Z"
        fill="#F6931D"
      />
      <path
        d="M34.0388 37.848C34.9927 37.848 35.7659 37.0747 35.7659 36.1209C35.7659 35.167 34.9927 34.3938 34.0388 34.3938C33.085 34.3938 32.3117 35.167 32.3117 36.1209C32.3117 37.0747 33.085 37.848 34.0388 37.848Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M27.9096 67.3791C28.8635 67.3791 29.6368 66.6059 29.6368 65.652C29.6368 64.6982 28.8635 63.9249 27.9096 63.9249C26.9558 63.9249 26.1825 64.6982 26.1825 65.652C26.1825 66.6059 26.9558 67.3791 27.9096 67.3791Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M124.957 31.359C125.473 31.359 125.891 30.9411 125.891 30.4256C125.891 29.91 125.473 29.4921 124.957 29.4921C124.442 29.4921 124.024 29.91 124.024 30.4256C124.024 30.9411 124.442 31.359 124.957 31.359Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M75.9247 55.1324C76.4402 55.1324 76.8581 54.7145 76.8581 54.199C76.8581 53.6835 76.4402 53.2656 75.9247 53.2656C75.4092 53.2656 74.9913 53.6835 74.9913 54.199C74.9913 54.7145 75.4092 55.1324 75.9247 55.1324Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M22.6204 41.9454C23.1359 41.9454 23.5539 41.5275 23.5539 41.012C23.5539 40.4964 23.1359 40.0785 22.6204 40.0785C22.1049 40.0785 21.687 40.4964 21.687 41.012C21.687 41.5275 22.1049 41.9454 22.6204 41.9454Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M34.6928 65.533C35.2083 65.533 35.6262 65.1151 35.6262 64.5995C35.6262 64.084 35.2083 63.6661 34.6928 63.6661C34.1773 63.6661 33.7594 64.084 33.7594 64.5995C33.7594 65.1151 34.1773 65.533 34.6928 65.533Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M55.8659 62.3756C56.3814 62.3756 56.7993 61.9577 56.7993 61.4422C56.7993 60.9267 56.3814 60.5087 55.8659 60.5087C55.3503 60.5087 54.9324 60.9267 54.9324 61.4422C54.9324 61.9577 55.3503 62.3756 55.8659 62.3756Z"
        fill="url(#paint18_linear)"
      />
      <path
        d="M86.5114 47.7034C87.0269 47.7034 87.4449 47.2855 87.4449 46.77C87.4449 46.2544 87.0269 45.8365 86.5114 45.8365C85.9959 45.8365 85.578 46.2544 85.578 46.77C85.578 47.2855 85.9959 47.7034 86.5114 47.7034Z"
        fill="url(#paint19_linear)"
      />
      <path
        d="M124.957 31.0716C125.314 31.0716 125.604 30.7823 125.604 30.4254C125.604 30.0685 125.314 29.7792 124.957 29.7792C124.601 29.7792 124.311 30.0685 124.311 30.4254C124.311 30.7823 124.601 31.0716 124.957 31.0716Z"
        fill="#82A9FF"
      />
      <path
        d="M131.208 85.326C131.565 85.326 131.854 85.0367 131.854 84.6798C131.854 84.3229 131.565 84.0336 131.208 84.0336C130.851 84.0336 130.562 84.3229 130.562 84.6798C130.562 85.0367 130.851 85.326 131.208 85.326Z"
        fill="#82A9FF"
      />
      <path
        d="M130.216 50.043C130.883 50.043 131.423 49.5025 131.423 48.8357C131.423 48.169 130.883 47.6285 130.216 47.6285C129.549 47.6285 129.009 48.169 129.009 48.8357C129.009 49.5025 129.549 50.043 130.216 50.043Z"
        fill="#A46AAC"
      />
      <path
        d="M80.32 61.309L40.3025 98.9837V154.461H120.336V98.9837L80.32 61.309Z"
        fill="url(#paint20_linear)"
      />
      <path
        d="M119.931 153.244H40.7082V98.8936L80.32 61.9852L119.931 98.8936V153.244Z"
        fill="white"
      />
      <path
        d="M107.898 69.5557H52.7407V134.451H107.898V69.5557Z"
        fill="url(#paint21_linear)"
      />
      <path
        d="M107.201 70.0969H53.4372V134.451H107.201V70.0969Z"
        fill="#BBEBFF"
      />
      <path
        d="M40.7082 98.8935L80.32 126.069L40.7082 153.244V98.8935Z"
        fill="#F5F5F5"
      />
      <path
        d="M119.931 98.8935L80.32 126.069L119.931 153.244V98.8935Z"
        fill="#F5F5F5"
      />
      <path
        d="M40.7082 153.242L78.7644 123.632C79.2072 123.283 79.7544 123.094 80.318 123.094C80.8815 123.094 81.4287 123.283 81.8716 123.632L119.931 153.242H40.7082Z"
        fill="url(#paint22_linear)"
      />
      <path
        d="M40.7082 153.242L78.7644 124.925C79.2135 124.591 79.7583 124.411 80.318 124.411C80.8777 124.411 81.4225 124.591 81.8716 124.925L119.931 153.242H40.7082Z"
        fill="white"
      />
      <path
        d="M72.8163 82.5344H61.595V84.4275H72.8163V82.5344Z"
        fill="#90DEFF"
      />
      <path
        d="M99.0435 87.5376H61.595V89.4307H99.0435V87.5376Z"
        fill="#F5F5F5"
      />
      <path
        d="M99.0435 90.7815H61.595V92.6746H99.0435V90.7815Z"
        fill="#F5F5F5"
      />
      <path
        d="M99.0435 94.0268H61.595V95.9199H99.0435V94.0268Z"
        fill="#F5F5F5"
      />
      <path
        d="M99.0435 97.2707H61.595V99.1638H99.0435V97.2707Z"
        fill="#F5F5F5"
      />
      <path
        d="M78.6291 100.516H61.595V102.409H78.6291V100.516Z"
        fill="#F5F5F5"
      />
      <path
        d="M99.0436 100.516H82.0095V102.409H99.0436V100.516Z"
        fill="#1C51B9"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="18.1002"
          y1="54.7919"
          x2="18.1002"
          y2="49.1772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="3102.18"
          y1="1414.88"
          x2="3102.18"
          y2="1245.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="4708.91"
          y1="1687.5"
          x2="4708.91"
          y2="1517.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="1631.37"
          y1="1501.77"
          x2="1631.37"
          y2="1396.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="3241.77"
          y1="1239.51"
          x2="3241.77"
          y2="1134.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="529.471"
          y1="381.666"
          x2="529.471"
          y2="354.279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="531.912"
          y1="567.185"
          x2="531.912"
          y2="539.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="1310.61"
          y1="342.609"
          x2="1310.61"
          y2="315.223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="1649.91"
          y1="606.242"
          x2="1649.91"
          y2="578.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="2320.35"
          y1="881.032"
          x2="2320.35"
          y2="816.79"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="1828.96"
          y1="942.911"
          x2="1828.96"
          y2="878.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="1519.56"
          y1="582.557"
          x2="1519.56"
          y2="518.315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="434.857"
          y1="633.516"
          x2="434.857"
          y2="569.274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="314.739"
          y1="1212.27"
          x2="314.739"
          y2="1148.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="1255.47"
          y1="288.072"
          x2="1255.47"
          y2="269.307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="713.576"
          y1="550.806"
          x2="713.576"
          y2="532.041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="124.475"
          y1="405.071"
          x2="124.475"
          y2="386.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="257.896"
          y1="665.752"
          x2="257.896"
          y2="646.987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="491.895"
          y1="630.858"
          x2="491.895"
          y2="612.093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="830.575"
          y1="468.703"
          x2="830.575"
          y2="449.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="34907.7"
          y1="64448.9"
          x2="34907.7"
          y2="919.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="24082.5"
          y1="35418.5"
          x2="24082.5"
          y2="4585.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="47597.9"
          y1="23840.8"
          x2="47597.9"
          y2="17186.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default function EmailFigure() {
  const appContext = useUniversalCtx()
  const Figure = appContext?.isReach ? ReachFigure : LifeFigure
  return <Figure />
}
