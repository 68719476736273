import React from "react"

const ElevateLogo = ({ className, style, onClick }) => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    onClick={onClick}
    viewBox="0 0 80 80"
  >
    <path d="M13.1895 35.4255C12.9433 31.7607 14.713 29.1073 17.1569 29.4364C19.6009 29.7656 21.7958 31.9442 21.9646 34.1862C22.1245 36.4943 23.0984 42.7454 21.0748 44.2886C19.1261 45.7747 13.5107 39.033 13.1895 35.4255Z" fill="#A46AAC"/>
    <path d="M26.0357 23.1371C27.6824 18.0684 31.4982 15.45 34.6121 17.2126C37.7261 18.9752 39.5061 23.0943 38.5232 26.2051C37.4926 29.4 35.4298 38.3448 31.8712 39.3308C28.4445 40.2804 24.5208 28.1692 26.0357 23.1371Z" fill="#F6931D"/>
    <path d="M55.0573 23.1496C56.6183 20.647 55.918 16.8333 53.6561 14.7272C51.3226 12.5766 47.5543 14.0004 45.1013 17.9331C42.7199 21.9104 43.5698 32.9685 46.6784 32.921C49.8762 32.7304 53.6125 25.6254 55.0573 23.1496Z" fill="#6BBF71"/>
    <path d="M66.8063 15.7499C67.6499 14.3585 67.0409 11.9405 65.5359 10.3952C64.0308 8.84995 61.6757 9.35336 60.4049 11.5677C59.0934 13.7402 60.1261 20.7099 62.0872 20.9971C64.0409 21.2103 66.0033 17.183 66.8063 15.7499Z" fill="#C3F590"/>
    <path d="M68.8713 13.4672C66.255 21.9743 63.2108 26.89 54.0104 33.4496C49.7779 36.4673 43.3492 38.783 36.5045 41.2486C28.4706 44.1426 19.8636 47.243 13.5619 51.9272C5.73297 57.7467 5.11023 64.7719 6.89972 64.5587C7.5735 64.4784 8.44033 63.8588 9.27644 63.2611C10.6609 62.2714 11.9612 61.3419 12.1612 63.0213C12.4821 65.7149 14.2583 64.9099 15.0594 64.4973C15.8307 64.0999 16.056 63.5775 16.4148 62.7454C17.0796 61.2035 18.203 58.5981 24.1091 53.7545C26.5523 51.7509 29.5456 50.0449 32.7851 48.4562C34.348 52.1201 40.7645 57.6045 44.7907 58.267C50.067 59.0179 54.0971 56.743 53.884 53.1712C53.671 49.5994 50.7549 46.1888 47.5289 45.7031C47.0453 45.6332 46.4428 45.5294 45.7585 45.4116C44.4094 45.1792 42.7428 44.8921 41.0448 44.7026C43.4829 43.6271 45.9201 42.5367 48.248 41.3672C50.343 43.8246 58.4712 45.7696 62.1487 44.4751C66.492 42.8569 68.6397 39.4488 66.9983 36.7329C65.4771 34.2444 62.1881 32.815 59.4997 33.5273C60.6247 32.3437 61.6224 31.1389 62.5063 29.9368C62.5011 30.0217 62.5034 30.1048 62.5138 30.1858C62.8015 31.8983 68.904 31.2894 70.7755 29.6203C72.6842 27.9788 73.0793 25.7343 71.7022 24.7136C70.3251 23.6929 68.2032 23.6771 67.0048 24.7494C66.8836 24.8518 66.7409 24.9698 66.5823 25.101L66.5818 25.1014C66.0086 25.5756 65.2272 26.222 64.5018 26.9262C69.24 18.9596 69.3134 12.0299 68.8713 13.4672Z" fill="#5B4B68"/>
  </svg>

)

export default ElevateLogo
