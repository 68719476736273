import { Heading, Text } from "app/wellzesta-ui"
import InternalLink from "app/common/InternalLink"
import EmailFigure from "./EmailFigure"

export default function CheckEmailStep({ email, hidePinLogin }) {
  return (
    <div className="space-y-5 text-white flex flex-col items-center text-center w-full">
      <EmailFigure />
      <Heading size="md">Check your e-mail</Heading>
      <Text as="p" size="sm" className="font-semibold">
        We’ve just sent an email to
        <span className="text-accent"> {email}</span>
        <br /> containing a link that&apos;ll sign you in super quickly.
      </Text>
      {!hidePinLogin && (
        <Text as="p" size="sm" className="font-semibold">
          Instead, I will log in
          <InternalLink
            theme="transparent"
            href={{ pathname: "/pin-login", query: { email } }}
            className="underline justify-center"
          >
            using PIN
          </InternalLink>
        </Text>
      )}
    </div>
  )
}
